import React from "react";
import Layout from "../../components/layout";
import CTA from "../../components/cta";
import PreFooter from "../../components/prefooter";
import ProductsNav from "../../components/navigation/ProductsNav";
import ProductsNavItem from "../../components/navigation/ProductsNavItem";
import AppFeatures from "../../components/appfeatures";

import Icon from "../../components/icon";

import VideoFirstWordmark from "../../components/icon/icons/vf-wordmark-rgb.icon";

import VideoFirstIcon from "../../components/icon/icons/vf-icon-rgb.icon";
import VideoFirstSlackIntegrationIcon from "../../components/icon/icons/vf-icon-slack-integration.icon";

import ImageUIComparision from "../../components/icon/icons/ui-comparison.svg";
import ImageFoldersVersions from "../../components/icon/icons/folders-and-versions.svg";
import ImageTeamApproval from "../../../static/images/team-approval.png";
import Image24HTest from "../../../static/images/24-hour-test.png";
import ImageGlobalTeam from "../../components/icon/icons/global-team.svg";
import Container from "../../components/container";
import ImageApp from "../../components/landing/app-screenshot.png";

const AppPage = () => (
  <Layout pageTitle="App - Products">
    <main>
      <ProductsNav>
        <ProductsNavItem
          text={"Video First App"}
          icon={VideoFirstIcon}
          link={"/products/app/"}
          active={true}/>
        <ProductsNavItem
          text={"Slack App"}
          icon={VideoFirstSlackIntegrationIcon}
          width="40"
          link={"/products/slack/"}
          active={false}/>
        {/*<ProductsNavItem*/}
          {/*text={"Video First Capture"}*/}
          {/*icon={VideoFirstCapture}*/}
          {/*link={"/products/capture/"}*/}
          {/*active={false}/>*/}
      </ProductsNav>
      <Container
        verticalPadding={false}
        extraClasses={"vf-wallpaper-minimal tc pv3 pv4-ns fade-out"}
        maxWidth={1200}
      >
        <div className={"w-100"}>
          <img src={ImageApp} className={"w-70 mh-auto mb4 mb5-ns"}/>
        </div>
        <CTA/>
      </Container>

      <Container verticalPadding={false} extraClasses="pv4" maxWidth={1000}>
        <AppFeatures
          title={"Are your design visuals out of sync with your user interface?"}
          text={"Our dashboard uses captured video to help ensure your product UI does what it should."}
          image={ImageUIComparision}
          reversed/>
        <AppFeatures
          title={"Do you lack visibility on your existing suite of UI tests?"}
          text={"Lots of companies are still using spreadsheets and outdated technologies. Video First provides a central repository for your UI testing."}
          image={ImageFoldersVersions}/>
        <AppFeatures
          title={"Has your team ever caused regressions in your user interfaces?"}
          text={"Regressions in your UI can be embarrassing and expensive. Video First provides a visual audit log of all your testing."}
          image={ImageTeamApproval}
          reversed/>
        <AppFeatures
          title={"Do you find your manual testing is expensive and error prone?"}
          text={"Manual testing is necessary when initially building your software but is expensive when catching regressions. Video First captures video from cost efficient automated testing."}
          image={Image24HTest}/>
        <AppFeatures
          title={"Is managing your remote testing teams causing headaches?"}
          text={"We live in a connected world. Video First provides a central collaborative hub for all your testing."}
          image={ImageGlobalTeam}
          reversed/>
      </Container>

      <Container extraClasses={"vf-wallpaper-minimal-ns tc"} maxWidth={1200}>
        <h3 className="f4 f3-ns mb3">Why test with <b>Video First App</b>?</h3>
        <p className="f5 f4-ns mb5 mb6-ns mw7 mh-auto">A central collaborative repository of your
          test videos makes for a more effective product and means that the benefits of your testing
          regime are visible to colleagues and company stakeholders.</p>
        <div className="bg-white tc">
          <div className="cf ph4-ns pt5 dn db-ns">
            <div className="fl w-third">
              <h4 className="f4 mb4 sbb">Your Product</h4>
              <p className="f6">Manually upload (or automate) UI Test video footage</p>
            </div>
            <div className="fl w-third">
              <Icon icon={VideoFirstWordmark} width={"100%"}/>
            </div>
            <div className="fl w-third">
              <h4 className="f4 mb4 sb">Your Team & Stakeholders</h4>
              <p className="f6">Team feedback and approval process</p>
            </div>
          </div>
          <img src={require("../../components/icon/icons/diagram-desktop.svg")}
               className="w-100 dn db-ns"/>
          <div className="cf ph4-ns pb5 dn db-ns">
            <div className="fl w-third">
              <p className="f6">Rigorous testing increases product effectiveness</p>
            </div>
            <div className="fl w-third">
              <p className="f6">Repository of UI test video captures</p>
            </div>
            <div className="fl w-third">
              <p className="f6">Visible record of progress and ongoing diligence</p>
            </div>
          </div>
          <img
            src={require("../../components/icon/icons/diagram-mobile.svg")}
            className="w-100 db dn-ns"
          />
        </div>
      </Container>

      <Container extraClasses={"tc pv3 pv5-ns"} verticalPadding={false} maxWidth={1200}>
        <CTA/>
      </Container>
      <PreFooter/>
    </main>
  </Layout>
);

export default AppPage;
